import { convert } from 'html-to-text';

export const groupBy = (arr: Array<any>, callback: any) => {
    return arr.reduce((acc, currentItem: any) => {
        const key = callback(currentItem);
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(currentItem);
        return acc;
    }, {});
};

export const formatDate = (date: string, long?: boolean): string => {
    return new Date(date).toLocaleDateString('en-GB', {
        day: 'numeric',
        month: long ? 'long' : 'short',
        year: 'numeric',
    });
};

export function formatFullDate(dateString: string): string {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
}

export const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

// export const enCodeLink = (headLine: string, id: string) => {
//     const text = convert(headLine, {});
//     return `/updates/${encodeURI(`${text.replace(/\s+/g, '-')}~${id}`)}`;
// };

export const enCodeLink = (headLine: string, id: string) => {
    // Remove HTML tags
    const sanitizedHeadline = headLine.replace(/<[^>]*>/g, '').replace(/\?/g, '');

    // Convert spaces to hyphens and encode URI
    const text = sanitizedHeadline.trim().replace(/\s+/g, '-');

    return `/updates/${encodeURI(`${text}~${id}`)}`;
};

export const enCodeEventLink = (eventName: string, eventId: string) => {
    const text = convert(eventName, {});
    return `/events/${encodeURI(`${text.replace(/\s+/g, '-')}~${eventId}`)}`;
};
