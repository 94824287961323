'use client';

import VOHModal from '@/components/Home/StartUpIncubator/VOHModal';
import ReUsableSectionHeading from '@/components/ReUsables/ReUsableSectionHeading';
import Image from 'next/image';
import { useState } from 'react';

export default function StartUpIncubator() {
    const [open, setOpen] = useState(false);
    return (
        <>
            <ReUsableSectionHeading name="VOH Incubator" href="#" />
            <Image
                src={'https://drive.google.com/uc?export=download&id=1WA2aEDaQmuu9kx_2wVRlvY9mlDnG5_iO'}
                alt=""
                className="w-full cursor-pointer"
                onClick={() => setOpen(true)}
                width={800}
                height={800}
            />
            <VOHModal open={open} setOpen={setOpen}  />
        </>
    );
}
