'use client';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, EffectCoverflow } from 'swiper/modules';
import React, { ReactNode, useState } from 'react';
import LoadingComponent from './LoadingComponent';

interface ReUsableSliderProps {
    loading?: boolean;
    children: ReactNode;
    slideNumber: number;
    autoPlay?: boolean;
    gap?: number;
    breakpoints?: {
        [key: number]: {
            slidesPerView: number;
            spaceBetween?: number;
        };
    };
    coverflow?: boolean;
}

export default function ReUsableSlider({
    children,
    slideNumber,
    autoPlay,
    gap,
    breakpoints,
    loading,
    coverflow,
}: ReUsableSliderProps) {
    const [loadings, setLoadings] = useState(!!loading);

    return (
        <Swiper
            autoplay={autoPlay && { pauseOnMouseEnter: true }}
            // navigation
            slidesPerView={slideNumber}
            modules={[Autoplay, Navigation, EffectCoverflow]}
            className="py-1"
            spaceBetween={gap}
            breakpoints={{ ...breakpoints }}
            onSwiper={() => setLoadings(false)}
            coverflowEffect={
                coverflow
                    ? {
                          rotate: 80,
                          depth: 100,
                          modifier: 0.2,
                      }
                    : {}
            }
            grabCursor={true}
            effect={coverflow ? 'coverflow' : ''}
        >
            {loadings && <LoadingComponent className="min-h-[300px] overflow-hidden" />}
            {!loadings &&
                React.Children.map(children, (child, index) => (
                    <SwiperSlide key={index} className="h-auto">
                        {child}
                    </SwiperSlide>
                ))}
        </Swiper>
    );
}
